<section class="section speaking" id="speaking">
  <div class="container">
    <div class="section-box">
      <div data-aos="fade-up" data-aos-duration="1000">
        <h3 class="section-title">
          <span class="n-section-title">04.</span>
          {{ "FeatureSpeaking.Title" | translate }}
        </h3>
      </div>

      <div
        *ngFor="
          let speaking of 'FeatureSpeaking.Speaking' | translate;
          let i = index
        "
        class="d-flex speaking-container"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <owl-carousel-o
          class="owl-carousel-o"
          [options]="customOptions"
          *ngIf="i % 2 == 0"
        >
          <ng-container *ngFor="let img of speaking.imgs">
            <ng-template carouselSlide [id]="speaking.Title + slide + i">
              <div #imgContainer class="img-container">
                <img
                  class="img-feature-speaking rounded"
                  [src]="img"
                  [alt]="speaking.Title"
                />
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
        <div
          class="img-feature-speaking-container"
          data-aos="fade-up"
          data-aos-duration="1000"
          *ngIf="i % 2 == 0"
        >
          <div class="img-feature-speaking-box">
            <div class="img-container" style="width: auto">
              <img
                class="img-feature-speaking rounded"
                [src]="speaking.imgs[0]"
                [alt]="speaking.imgs[0]"
                width="600px"
              />
            </div>
          </div>
        </div>
        <div
          [class.speaking-info-right]="i % 2 == 0"
          [class.speaking-info-left]="i % 2 != 0"
          class="h-100"
          style="background-image: url('{{ speaking.img }}');"
        >
          <div class="speaking-box">
            <h4
              [class.text-right]="i % 2 == 0"
              [class.text-left]="i % 2 != 0"
              class="feature-speaking"
            >
              {{ "FeatureSpeaking.Label" | translate }}
            </h4>
            <h5
              [class.text-right]="i % 2 == 0"
              [class.text-left]="i % 2 != 0"
              class="speaking-name"
            >
              {{ speaking.Title }}
            </h5>

            <div class="speaking-description-box" (click)="debug()">
              <p class="mb-0" [innerHTML]="speaking.Description"></p>
            </div>
            <ul
              [class.justify-content-end]="i % 2 == 0"
              [class.justify-content-start]="i % 2 != 0"
              class="speaking-skills"
            >
              <li *ngFor="let technology of speaking.Technologies" class="ml-3">
                <span class="underline technology">{{ technology }}</span>
              </li>
            </ul>
            <div
              [class.text-right]="i % 2 == 0"
              [class.text-left]="i % 2 != 0"
              class="speaking-links"
            >
              <a
                *ngIf="speaking['ghLink']"
                style="color: inherit"
                [href]="speaking['ghLink']"
                target="_blank"
                ><i class="ml-3 fab fa-github"></i
              ></a>
              <a
                *ngIf="speaking['demoLink']"
                style="color: inherit"
                [href]="speaking['demoLink']"
                target="_blank"
                ><i class="ml-3 fas fa-external-link-alt"></i
              ></a>
            </div>
          </div>
        </div>
        <owl-carousel-o
          class="owl-carousel-o"
          [options]="customOptions"
          *ngIf="i % 2 != 0"
        >
          <ng-container *ngFor="let img of speaking.imgs">
            <ng-template carouselSlide [id]="speaking.Title + slide + i">
              <div class="img-container">
                <img
                  class="img-feature-speaking rounded"
                  [src]="img"
                  [alt]="speaking.Title"
                />
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
        <div
          class="img-feature-speaking-container"
          data-aos="fade-up"
          data-aos-duration="1000"
          *ngIf="i % 2 != 0"
        >
          <div class="img-feature-speaking-box">
            <div class="img-container" style="width: auto">
              <img
                class="img-feature-speaking rounded"
                [src]="speaking.imgs[0]"
                [alt]="speaking.imgs[0]"
                width="600px"
              />
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  </div>
</section>
